import React from 'react';
import './Footer.css';
import { FaTwitter, FaLinkedin } from 'react-icons/fa'; // Icons import karte hain

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/about">About Us</a></li>
            <li><a href="/contact">Contact Us</a></li>
            <li><a href="/faq">FAQ</a></li>
            <li><a href="/mission">Mission</a></li>
            {/* <li><a href="/what-we-do">What We Do</a></li> */}
          </ul>
        </div>
        <div className="footer-section">
          <h3>Connect with Us</h3>
          <div className="social-icons">
            <a href="https://x.com/ParthVe13132317" target="_blank" rel="noopener noreferrer" className="social-icon"><FaTwitter /></a>
            <a href="https://www.linkedin.com/in/parth-verma-912225195/" target="_blank" rel="noopener noreferrer" className="social-icon"><FaLinkedin /></a>
          </div>
        </div>
        <div className="footer-section">
          <h3>Contact Us</h3>
          <p>Email: founder@bakz4ever.com</p>
          <p>Phone: +91-8533883555</p>
        </div>
      </div>
      <div className="footer-copyright">
        <p>© 2025 Bakz4ever. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
