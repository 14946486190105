import React, { useState, useEffect } from 'react';
import '../styles/Home.css';

// Import images
import Drought2 from '../assets/images/Drought2.jpg';
import flood from '../assets/images/flood.jpg';
import forestFire from '../assets/images/forestFire.jpg';
import glacierMelt from '../assets/images/glacierMelt.jpg';

function Home() {
  const images = [flood, forestFire, glacierMelt, Drought2];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000); // 2 seconds interval
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [images.length]);

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="home-page">
      <div
        className="slider"
        style={{ backgroundImage: `url(${images[currentIndex]})` }}
      >
        {/* Pagination dots on top of the slider */}
        <div className="pagination-dots">
          {images.map((_, index) => (
            <span
              key={index}
              className={`dot ${currentIndex === index ? 'active' : ''}`}
              onClick={() => handleDotClick(index)}
            ></span>
          ))}
        </div>

        {/* Overlay content */}
        <div className="overlay-content">
          <h1>Bakz4ever is at the forefront of driving corporate sustainability efforts worldwide.</h1>
          <p>CarbonBank technology is a 2 in 1 sustainable model for low cost Direct Air Capture along with long duration Energy Storage for 100% renewable grid.</p>
        </div>
      </div>

      {/* Who We Are and What We Do sections below the pagination dots */}
      {/* <div className="info-sections">
        <div className="info-column">
          <h2>Who We Are</h2>
          <p>We are committed to making a difference through sustainable practices.</p>
        </div>
        <div className="line"></div>
        <div className="info-column">
          <h2>What We Do</h2>
          <p>We provide innovative solutions for a sustainable future.</p>
        </div>
      </div> */}
    </div>
  );
}

export default Home;
