// src/pages/Faq.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Faq.css';

function Faq() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [bubbleAnimation, setBubbleAnimation] = useState(false);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is CarbonBank?",
      answer: "CarbonBank is an innovative project that uses Cryogenic Direct Air Capture (DAC) technology to remove CO2 from the atmosphere and stores it as solid CO2 in deep ocean locations. It aims to combat climate change by providing scalable and sustainable carbon removal solutions."
    },
    {
      question: "How does Cryogenic DAC work?",
      answer: "Cryogenic DAC cools air to extremely low temperatures, freezing out CO2 with high efficiency. The captured CO2 is then converted into a solid state for safe and permanent storage."
    },
    {
      question: "Why store CO2 in the ocean?",
      answer: "Deep ocean storage leverages stable environmental conditions—high pressure and low temperatures—to ensure permanent sequestration of solid CO2, minimizing the risk of re-release."
    },
    {
      question: "What makes CarbonBank unique?",
      answer: "CarbonBank combines cryogenic DAC, ocean storage, and renewable energy integration to deliver a dual-impact solution for carbon removal and utilization. Its modular systems are scalable and adaptable to various settings."
    },
    {
      question: "How does CarbonBank contribute to sustainability?",
      answer: "By removing CO2 from the atmosphere, storing it safely, and exploring industrial uses for captured carbon, CarbonBank supports global decarbonization efforts and promotes a circular economy."
    },
    {
      question: "Who can partner with CarbonBank?",
      answer: "CarbonBank collaborates with industries, policymakers, and communities to scale its solutions. If you're interested in partnering, reach out to us for more details."
    },
    {
      question: "How is CarbonBank powered?",
      answer: "CarbonBank integrates renewable energy systems to power its operations, ensuring minimal environmental impact and enhancing sustainability."
    },
    {
      question: "What are the benefits of CarbonBank?",
      answer: [ 
        "Significant CO2 removal from the atmosphere.",
        "Permanent and safe carbon storage.",
        "Contribution to renewable energy systems.",
        "Job creation and community engagement."
      ]
    },
    {
      question: "How can I get involved?",
      answer: (
        <p>
          You can support CarbonBank by partnering with us, investing in our solutions, or spreading awareness about our mission. 
          <Link to="/contact"> Contact us</Link> to learn more.
        </p>
      ),
    },
    {
      question: "Where can I learn more?",
      answer: "Visit our website or contact us directly for detailed information about our technology, mission, and impact."
    }
    // {
    //   question: "What is Direct Air Capture (DAC)?",
    //   answer: "DAC is a technology designed to capture carbon dioxide (CO2) directly from the atmosphere for storage or reuse."
    // },
    // {
    //   question: "What is energy storage, and why is it important?",
    //   answer: "Energy storage systems store energy for later use, ensuring reliability, balancing supply and demand, and enabling the use of renewable energy sources like solar and wind."
    // },
    // {
    //   question: "How does CarbonBank combine DAC and energy storage?",
    //   answer: "CarbonBank integrates low-cost DAC with long-duration energy storage to create a sustainable, dual-purpose system for climate action."
    // },
    // {
    //   question: "What are the benefits of using DAC over traditional carbon capture methods?",
    //   answer: "DAC can be deployed anywhere, targets existing atmospheric CO2, and works independently of emission sources."
    // },
    // {
    //   question: "Why are you capturing Carbon?",
    //   answer: "Capturing carbon reduces CO2, combats climate change, achieves net-zero emissions, and supports sustainability efforts."
    // },
    // {
    //   question: "What are the benefits of carbon capturing from the environment?",
    //   answer: "Carbon capturing lowers greenhouse gases, mitigates climate change, protects ecosystems, improves air quality, and enables sustainable energy solutions for a healthier planet."
    // }
  ];

  const filteredFaqData = faqData.filter(faq =>
    faq.question.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const timer = setTimeout(() => setBubbleAnimation(true), 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="faq-page">
      <div className={`faq-container ${bubbleAnimation ? 'bubble-animation' : ''}`}>
        <h1>Frequently Asked Questions</h1>
        
        <input
          type="text"
          className="faq-search"
          placeholder="Search FAQs..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <div className="faq-list">
          {filteredFaqData.length > 0 ? (
            filteredFaqData.map((faq, index) => (
              <div key={index} className={`faq-card ${activeIndex === index ? 'active' : ''}`}>
                <div className="faq-question" onClick={() => toggleAccordion(index)}>
                  <h3>{faq.question}</h3>
                  <span className={`icon ${activeIndex === index ? 'rotate' : ''}`}>&#9660;</span>
                </div>
                <div className={`faq-answer ${activeIndex === index ? 'show' : ''}`}>
                  {/* Check if answer is an array or a string */}
                  {Array.isArray(faq.answer) ? (
                    <ul>
                      {faq.answer.map((point, i) => (
                        <li key={i}>{point}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>{faq.answer}</p>
                  )}
                </div>
              </div>
            ))
          ) : (
            <p>No results found for "{searchTerm}".</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Faq;

