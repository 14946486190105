import React from 'react';
import '../styles/AboutUs.css';
import aboutUsImage from '../assets/images/AboutUs.webp'; // Adjust the path according to your structure
import ParthVerma from '../assets/images/ParthVerma.png'; // Image for team member 1
import KrishnaUpman from '../assets/images/KrishnaUpman.png'; // Image for team member 2
import PawaneshKumar from '../assets/images/PawaneshKumar.png'; // Image for team member 3

function AboutUs() {
  const coreValues = [
    "Integrity",
    "Innovation",
    "Accountability",
    "Excellence",
    "Sustainability"
  ];

  const teamMembers = [
    {
      name: "Parth Verma",
      position: "CEO & Founder",
      image: ParthVerma,
      linkedin: "https://www.linkedin.com/in/parth-verma-912225195/", // Replace with actual LinkedIn URL
      twitter: "https://x.com/ParthVe13132317", // Replace with actual Twitter URL
    },
    {
      name: "Pawanesh Kumar",
      position: "COO & Technical Head",
      image: PawaneshKumar,
      linkedin: "https://www.linkedin.com/in/pawanesh-kumar-993031249/", // Replace with actual LinkedIn URL
      twitter: "https://x.com/Pawanesh7298", // Replace with actual Twitter URL
    }
    ,
    {
      name: "Krishna Kant Upman",
      position: "CTO & Digital Head",
      image: KrishnaUpman,
      linkedin: "https://www.linkedin.com/in/krishna-kant-upman-776b7217b/", // Replace with actual LinkedIn URL
      twitter: "https://twitter.com/krishnakantupman", // Replace with actual Twitter URL
    }
  ];

  return (
    <div className="aboutus-page">
      <section className="content-section">
        <div className="image-container">
          <img src={aboutUsImage} alt="About Us" />
        </div>
        <div className="content">
          <h3>About Us</h3>
          <h1>We store CO2 in Ocean</h1>
          <p>
            Impact is measured in two ways: first in carbon removal, how much net CO2 is being removed from the air in tons, and second in energy storage, what capacity of storage and for how long. Basically, for renewables, we need long duration or time between 12 to 20 hours. With our CarbonBank, if the plant capacity is in MegaWattHour or GWh, with every charging and discharging cycle, we will capture a certain volume of CO2, and considering the long life of the plant, it would have captured more CO2 than its storage capacity.
          </p>
        </div>
      </section>

      <section className="values-section">
        <h2>Core Values</h2>
        <div className="values-grid">
          {coreValues.map((value, index) => (
            <div key={index} className="value-item">{value}</div>
          ))}
        </div>
      </section>

      <section className="team-section">
        <h2>Meet Our Team</h2>
        <div className="team-grid">
          {teamMembers.map((member, index) => (
            <div key={index} className="team-member">
              <img src={member.image} alt={`${member.name} - ${member.position}`} />
              <h3>{member.name}</h3>
              <p>{member.position}</p>
              <div className="social-links-about">
                <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-linkedin"></i> {/* LinkedIn Icon */}
                </a>
                <a href={member.twitter} target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-twitter"></i> {/* Twitter Icon */}
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>

      <footer className="about-footer">
        <p>Please reach out to: <a href="/contact" className="contact-link">Contact Us</a></p>
      </footer>
    </div>
  );
}

export default AboutUs;
